import { ImageProps } from 'next/image'
import NextImage from 'next/image'
import { Props } from './Image'

export const ImageSimple = ({
  src,
  hasNoBlur,
  alt,
  quality = 70,
  fill,
  style,
  ...rest
}: Omit<ImageProps, 'src'> & Props) => {
  if (!src) {
    return null
  }

  return (
    <NextImage
      src={src}
      loading={rest.loading}
      placeholder={hasNoBlur ? 'empty' : 'blur'}
      blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      fill={rest.width && rest.height ? false : !!fill}
      alt={alt}
      style={style}
      {...rest}
      unoptimized
    />
  )
}
