import clsx from 'clsx'
import { createMagentoClient } from 'graphql/magentoClient'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Link } from '~/elements/Link/Link'

import { ContentfulTag, Maybe } from '@contentfulTypes'
import { useRouteQuery } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  prefix?: string
  tags?: Maybe<ContentfulTag>[]
}

export const Breadcrumb = ({ tags, prefix = '' }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const slug = router.query['slug'] as string[]
  const stringSlug = Array.isArray(slug) ? slug.join('/') : slug
  const { data } = useRouteQuery(
    magentoClient,
    {
      slug: stringSlug?.replace(/^-/, ''),
    },
    { enabled: !!stringSlug },
  )
  const isPLP = data?.route?.type === 'CATEGORY'
  const path = router.asPath.split('?')[0].split('#')[0]
  const trimmedPath = isPLP ? path.split(/[/.]/).slice(1, -1) : path.split(/[/]/).slice(1)
  const isBlogPage = tags?.some((tag) => tag?.id === 'blog')
  const menuStyles = [
    'z-10',
    'flex',
    'w-full',
    'flex-wrap',
    'items-center',
    'gap-4',
    'bg-primary-white',
    'text-p-small',
    'text-grey-medium',
    '!text-primary-black',
  ]
  let basePath = '/'

  const isShallow = trimmedPath.length <= 1
  const hiddenClass = isShallow ? 'h-0 overflow-hidden' : 'p-4 lg:px-20'

  if (path.length > 1) {
    return (
      <menu className={clsx(menuStyles, 'breakout-content-width', hiddenClass)}>
        <li>
          <Link
            className="font-extralight !text-primary-black duration-75"
            href="/"
            title="link to home page"
          >
            {'Home'}
          </Link>
          <span className="ml-4 font-extralight">{'>'}</span>
        </li>

        {trimmedPath?.map((item, i) => {
          const isLastItem = i === trimmedPath.length - 1
          const formattedItem = item.replace(/-/g, ' ').replace('.html', '')
          basePath = `${basePath}${isPLP || item.includes('html') ? `${item}.html` : item}/`
          return (
            <li key={`${item}_${i}`} className="font-extralight capitalize">
              {isLastItem ? (
                `${prefix && `${prefix} `}${item === 'blog' ? t('magazine') : t(formattedItem)}`
              ) : (
                <Link className="font-extralight !text-primary-black duration-75" href={basePath}>
                  {isBlogPage && item === 'blog' ? t('magazine') : t(formattedItem)}
                </Link>
              )}
              {!isLastItem ? <span className="ml-4 font-extralight">{'>'}</span> : null}
            </li>
          )
        })}
      </menu>
    )
  }
  return null
}
