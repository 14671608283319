import { type ReactNode, useState, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { ErrorMessage } from '@hookform/error-message'
import { RegisterOptions, UseFormRegister, Path, FieldErrors, FieldValues } from 'react-hook-form'
import { Icon, Icons } from '../Icons/Icon'

export type InputMainProps = {
  children?: ReactNode
  name: string
  type?: 'text' | 'email' | 'tel' | 'password' | 'number' | 'radio'
  maxLength?: number
  required?: boolean
  autoComplete?: string
  placeholder?: string
  disabled?: boolean
  defaultValue?: string | null
  id?: string
  dark?: boolean
}

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  register: UseFormRegister<TFormValues>
  rules: RegisterOptions
  errors: FieldErrors<TFormValues>
} & Omit<InputMainProps, 'name'>

export const InputMain = <TFormValues extends FieldValues>({
  children,
  required,
  name,
  errors,
  maxLength,
  autoComplete,
  disabled,
  placeholder,
  register,
  rules,
  defaultValue = '',
  id,
  dark,
  ...rest
}: FormInputProps<TFormValues>) => {
  const hasError = !!errors?.[name]
  const { t } = useTranslation()

  const [value, setValue] = useState(defaultValue)

  const getIcon = useMemo((): Icons | undefined => {
    if (hasError && !disabled) {
      return 'error'
    }

    if (!hasError && !disabled && value && !children) {
      return 'checkmark'
    }
  }, [hasError, disabled, value, children])

  return (
    <div className="input-wrapper relative mb-6">
      <input
        disabled={disabled}
        maxLength={maxLength}
        required={required}
        autoComplete={autoComplete}
        value={value ?? undefined}
        id={id}
        placeholder={`${placeholder}${!rules.required ? ` (${t('Optional')})` : '*'}`}
        className={clsx(
          'w-full appearance-none rounded-[5px] bg-transparent px-5 py-[12px] text-12-20-sg outline-none transition-all focus:border-sg-black focus:ring-0',
          dark || (value && !disabled) ? 'border-sg-black' : 'border-sg-grey',
          hasError && !disabled && 'text-sg-red',
          disabled && 'pointer-events-none',
          !hasError && !disabled && value && 'border-sg-black',
          dark ? 'placeholder-sg-black/50' : 'placeholder-sg-grey',
        )}
        onFocus={(e) => {
          setValue(e.target.value)
        }}
        {...rest}
        {...(register &&
          register(name, {
            onChange: (e) => {
              setValue(e.target.value)
            },
            ...rules,
          }))}
      />
      {children}
      {!!getIcon && (
        <Icon
          name={getIcon as Icons}
          className="absolute right-4 top-1/2 h-4.5 w-4.5 -translate-y-1/2"
        />
      )}
      {!disabled && (
        <ErrorMessage
          errors={errors}
          name={name as any}
          render={({ message = '' }) => (
            <p className="error-message absolute top-full ml-5 pt-0.5 text-p-small font-extralight text-primary-red">
              {t(message)}
            </p>
          )}
        />
      )}
    </div>
  )
}
