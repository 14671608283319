import { ImageSimple } from './ImageSimple'
import { ImageOptimized } from './ImageOptimized'
import { ImageProps } from 'next/image'

export type Props = {
  hasNoBlur?: boolean
  alt: string
  quality?: number
  fill?: boolean
  src: ImageProps['src'] | undefined | null
}

export const Image = ({ src, ...props }: Omit<ImageProps, 'src'> & Props) => {
  if (!src) {
    return null
  }

  const stringSrc = src.toString()

  // Only apply optimization to image assets from Magento and CTF (not SVGs)
  if (
    !stringSrc.includes('.svg') &&
    (stringSrc.includes('b2b.benuta.com') || stringSrc.includes('ctfassets.net'))
  ) {
    return <ImageOptimized src={src} {...props} />
  }

  // If no optimization is needed, render the base ImageComponent
  return <ImageSimple src={src} {...props} />
}
