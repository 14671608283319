import { useRouter } from 'next/router'
import { JsonLdScript } from './JsonLdScript'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'

const WebsisteJsonLd: React.FC = () => {
  const router = useRouter()
  const swissLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const baseUrl = `https://www.${process.env.NEXT_PUBLIC_DOMAIN_NAME}/${
    process.env.NEXT_PUBLIC_COUNTRY_CODE === 'CH' ? `${swissLocale.slice(0, 2)}/` : ''
  }`

  return (
    <JsonLdScript
      id="website-json-ld"
      content={JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: baseUrl,
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${baseUrl}search?q={search_term_string}`,
          },
          'query-input': {
            '@type': 'PropertyValueSpecification',
            valueRequired: 'http://schema.org/True',
            valueName: 'search_term_string',
          },
        },
      })}
    />
  )
}

export { WebsisteJsonLd }
