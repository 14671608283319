import { ImageProps } from 'next/image'
import { CSSProperties, useMemo } from 'react'
import { hasQueryString } from '~/lib/hasQueryString'
import { Props as BaseImageProps } from './Image'

interface Props extends BaseImageProps {
  src: ImageProps['src']
}

export const ImageOptimized = ({
  src,
  hasNoBlur,
  alt,
  quality = 70,
  fill,
  style,
  ...rest
}: Omit<ImageProps, 'src'> & Props) => {
  const stringSrc = src.toString()

  const width = rest.width as number
  const deviceSizes = useMemo(
    () => (width && width < 2000 ? [1, 2] : [256, 384, 640, 750, 1080, 1200, 1920, 2048, 3840]),
    [width],
  )

  const finalSrc = stringSrc.includes('b2b.benuta.com')
    ? stringSrc.replace(/\/cache\/[a-f0-9]+\//, '/')
    : stringSrc.replace('downloads.ctfassets.net', 'images.ctfassets.net')

  const getOptimizedSrc = useMemo(() => {
    const size = deviceSizes[deviceSizes.length - 1]
    const calcualtedWidth = width && width < 2000 ? Math.floor(size * width) : size
    const srcFilter = stringSrc.includes('b2b.benuta.com')
      ? `width=${calcualtedWidth}&quality=${quality}`
      : `w=${calcualtedWidth}&q=${quality}&fm=webp`
    return hasQueryString(finalSrc as string)
      ? `${finalSrc}&${srcFilter}`
      : `${finalSrc}?${srcFilter}`
  }, [finalSrc, width, quality, deviceSizes, stringSrc])

  const optimizedSrcset = deviceSizes
    .map((size) => {
      if (width && width < 2000) {
        const calculatedWidth = Math.floor(size * width)
        const srcsetFilter = stringSrc.includes('b2b.benuta.com')
          ? `width=${calculatedWidth}&quality=${quality}`
          : `w=${calculatedWidth}&q=${quality}&fm=webp`
        return `${finalSrc}${
          hasQueryString(finalSrc as string) ? '&' : '?'
        }${srcsetFilter} ${size}x`
      } else {
        const srcsetFilter = stringSrc.includes('b2b.benuta.com')
          ? `width=${size}&quality=${quality}`
          : `w=${size}&q=${quality}&fm=webp`
        return `${finalSrc}${
          hasQueryString(finalSrc as string) ? '&' : '?'
        }${srcsetFilter} ${size}w`
      }
    })
    .join(', ')

  const optimizedStyle: CSSProperties = (
    rest.width
      ? style
      : {
          position: 'absolute',
          height: '100%',
          width: '100%',
          inset: '0px',
          color: 'transparent',
          ...style,
        }
  ) as CSSProperties

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      decoding="async"
      loading={rest.loading}
      src={getOptimizedSrc}
      srcSet={optimizedSrcset}
      alt={alt || ''}
      style={optimizedStyle}
      placeholder={hasNoBlur ? 'empty' : 'blur'}
      {
        // this prevents react from throwing error about invalid attribute
        ...{
          ...rest,
          priority: null,
        }
      }
    />
  )
}
